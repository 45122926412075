<template>
  <div class="mt-1 mx-3">
    <bank-accounts-create> </bank-accounts-create>
  </div>
</template>

<script>
import BankAccountsCreate from "@/components/profile/bank-accounts/create.vue";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    BankAccountsCreate,
  },
  created() {
    this.notifyQueue({
      text: "Crea Banca",
      path: "bank_accounts.create",
      level: 3,
    });
  },
};
</script>
