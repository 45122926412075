var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "b-card",
                                          {
                                            attrs: {
                                              header: "Dati Generali",
                                              "header-tag": "header",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card-text",
                                              [
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].abi.label,
                                                          vid: "abi",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .abi.label,
                                                          placeholder:
                                                            "Inserisci un codice ABI",
                                                          rules:
                                                            _vm.getRules("abi"),
                                                          maxlength: "5",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .abi,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "abi",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].abi",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].cab.label,
                                                          vid: "cab",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .cab.label,
                                                          placeholder:
                                                            "Inserisci un codice CAB",
                                                          rules:
                                                            _vm.getRules("cab"),
                                                          maxlength: "5",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .cab,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "cab",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].cab",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].code.label,
                                                          vid: "code",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .code.label,
                                                          placeholder:
                                                            "Inserisci un codice impresa mittente SIA",
                                                          rules: {
                                                            required: true,
                                                            alpha_num: true,
                                                          },
                                                          maxlength: "5",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .code,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "code",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].code",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].cuc.label,
                                                          vid: "cuc",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .cuc.label,
                                                          placeholder:
                                                            "Inserisci un codice univoco CBI",
                                                          rules:
                                                            _vm.getRules("cuc"),
                                                          maxlength: "8",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .cuc,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "cuc",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].cuc",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].identifier.label,
                                                          vid: "identifier",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .identifier.label,
                                                          placeholder:
                                                            "Inserisci un Creditor identifier",
                                                          rules:
                                                            _vm.getRules(
                                                              "identifier"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .identifier,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "identifier",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].identifier",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].iban.label,
                                                          vid: "iban",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .iban.label,
                                                          placeholder:
                                                            "Inserisci un codice IBAN agenzia",
                                                          rules:
                                                            _vm.getRules(
                                                              "iban"
                                                            ),
                                                          maxlength: "27",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .iban,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "iban",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].iban",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "referer_type",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].referer_type.label,
                                                          label:
                                                            "Seleziona un tipo codice individuale",
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .referer_type
                                                              .options,
                                                          rules:
                                                            _vm.getRules(
                                                              "referer_type"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .referer_type,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "referer_type",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].referer_type",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].support.label,
                                                          vid: "support",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .support.label,
                                                          placeholder:
                                                            "Inserisci una nome supporto",
                                                          rules:
                                                            _vm.getRules(
                                                              "support"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .support,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "support",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].support",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("b-row", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-input", {
                                                        attrs: {
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].title.label,
                                                          vid: "title",
                                                          label:
                                                            _vm.beForm[_vm.rep]
                                                              .title.label,
                                                          placeholder:
                                                            "Inserisci una ragione sociale Ist. Credito",
                                                          rules:
                                                            _vm.getRules(
                                                              "title"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .title,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "title",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].title",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-md-3",
                                                    },
                                                    [
                                                      _c("base-select", {
                                                        attrs: {
                                                          vid: "use_rid",
                                                          name: _vm.beForm[
                                                            _vm.rep
                                                          ].use_rid.label,
                                                          label:
                                                            "Seleziona un utilizzo flusso RID",
                                                          options:
                                                            _vm.beForm[_vm.rep]
                                                              .use_rid.options,
                                                          rules:
                                                            _vm.getRules(
                                                              "use_rid"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.form[_vm.rep]
                                                              .use_rid,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form[_vm.rep],
                                                              "use_rid",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form[rep].use_rid",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.resetForm()
                                                  },
                                                },
                                              },
                                              [_vm._v("Reset")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2756724130
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }