<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Generali" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].abi.label"
                        vid="abi"
                        :label="beForm[rep].abi.label"
                        v-model="form[rep].abi"
                        placeholder="Inserisci un codice ABI"
                        :rules="getRules('abi')"
                        maxlength="5"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].cab.label"
                        vid="cab"
                        :label="beForm[rep].cab.label"
                        v-model="form[rep].cab"
                        placeholder="Inserisci un codice CAB"
                        :rules="getRules('cab')"
                        maxlength="5"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].code.label"
                        vid="code"
                        :label="beForm[rep].code.label"
                        v-model="form[rep].code"
                        placeholder="Inserisci un codice impresa mittente SIA"
                        :rules="{ required: true, alpha_num: true }"
                        maxlength="5"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].cuc.label"
                        vid="cuc"
                        :label="beForm[rep].cuc.label"
                        v-model="form[rep].cuc"
                        placeholder="Inserisci un codice univoco CBI"
                        :rules="getRules('cuc')"
                        maxlength="8"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].identifier.label"
                        vid="identifier"
                        :label="beForm[rep].identifier.label"
                        v-model="form[rep].identifier"
                        placeholder="Inserisci un Creditor identifier"
                        :rules="getRules('identifier')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].iban.label"
                        vid="iban"
                        :label="beForm[rep].iban.label"
                        v-model="form[rep].iban"
                        placeholder="Inserisci un codice IBAN agenzia"
                        :rules="getRules('iban')"
                        maxlength="27"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="referer_type"
                        :name="beForm[rep].referer_type.label"
                        label="Seleziona un tipo codice individuale"
                        v-model="form[rep].referer_type"
                        :options="beForm[rep].referer_type.options"
                        :rules="getRules('referer_type')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].support.label"
                        vid="support"
                        :label="beForm[rep].support.label"
                        v-model="form[rep].support"
                        placeholder="Inserisci una nome supporto"
                        :rules="getRules('support')"
                      />
                    </div>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-input
                        :name="beForm[rep].title.label"
                        vid="title"
                        :label="beForm[rep].title.label"
                        v-model="form[rep].title"
                        placeholder="Inserisci una ragione sociale Ist. Credito"
                        :rules="getRules('title')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="use_rid"
                        :name="beForm[rep].use_rid.label"
                        label="Seleziona un utilizzo flusso RID"
                        v-model="form[rep].use_rid"
                        :options="beForm[rep].use_rid.options"
                        :rules="getRules('use_rid')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseIcon from "@/components/BaseIcon";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "bank_account",
      id: this.$route.params.id,
      form: {
        bank_account: {
          title: null,
          abi: null,
          cab: null,
          cuc: null,
          identifier: null,
          is_hidden: null,
          code: null,
          iban: null,
          referer_type: null,
          support: null,
          use_rid: null,
        },
      },
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseIcon,
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then(() => {
          this.$store.dispatch("auth/bank_accounts");
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Banca modificata`,
          });
          this.shortcut(
            "bank_accounts.index",
            null,
            null,
            "filterBankAccounts"
          );
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  created() {
    this.isLoading = true;
    this.fetchEditForm(this.repository, this.id).then(() => {
      this.isLoading = false;
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
